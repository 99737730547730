
<template>
  <div v-if="navigation" class="add-new-item-admin">
    <b-form @submit.prevent="onSubmit">
      <b-container class="full-w">
        <b-row>
          <b-col cols="12" sm="8">
            <b-card>
              <b-form-group
                id="input-group-1"
                :label="$t('forms.position')"
                label-for="position"
                :class="{ error: v$.navigation.position.$errors.length }"
              >
                <v-select
                  id="position"
                  v-model="navigation.position"
                  :options="positions"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.navigation.position.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>
            </b-card>

            <b-card v-if="menu && menu.length">
              <nested-draggable @itemDeleted="removeFromMenu" :items="menu" />
            </b-card>
          </b-col>

          <b-col cols="12" sm="4">
            <b-card>
              <h4>{{ $t("forms.customLink") }}</h4>
              <b-form-group :label="$t('forms.customLinkLabel')">
                <b-form-input v-model="newItem.name" />
              </b-form-group>
              <b-form-group :label="$t('forms.customLinkUrl')">
                <b-form-input v-model="newItem.url" />
              </b-form-group>
              <b-button variant="primary" class="mb-4" @click="addCustomLink">
                {{ $t("forms.menuAdd") }}
              </b-button>

              <h4>{{ $t("navigations.pages") }}</h4>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="selectedPages"
                  :aria-describedby="ariaDescribedby"
                  :options="options"
                  value-field="item"
                  text-field="text"
                  class="d-flex flex-column"
                />
              </b-form-group>
              <b-button variant="primary" @click="addPages">
                {{ $t("forms.menuAdd") }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BForm,
  BCol,
  BCard,
  BContainer,
  BFormGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue-3";
import vSelect from "vue-select";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import nestedDraggable from "./nested.vue";
import { v4 as uuidv4 } from "uuid";
import { preparePageItems, remove, iterate } from "../navigation";

export default {
  props: {
    navigationObj: { type: Object, default() {} },
    action: { type: String, default: null },
  },
  components: {
    BRow,
    BContainer,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    vSelect,
    nestedDraggable,
  },
  data() {
    return {
      newItem: {
        id: null,
        items: [],
        url: "",
        name: "",
      },
      success: false,
      navigation: this.navigationObj,
      positions: [],
      pages: [],
      selectedPages: [],
      options: [],
      enableAdding: true,
      error: null,
      menu: [],
    };
  },
  mixins: [ListUtils],
  created() {
    this.loadPages();
    this.getEnumList("position", this.positions);
    if(this.navigation.menu) {
      this.menu = this.navigation.menu
      iterate(this.menu, 0, false)
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      navigation: {
        position: { required },
      },
    };
  },
  watch: {
    action() {
      if(this.action) {
        this.onSubmit()
      }
    },
  },
  methods: {
    addCustomLink() {
      const newItem = JSON.parse(JSON.stringify(this.newItem));
      newItem.id = uuidv4();
      newItem.items = [];
      this.menu.push(newItem);
      this.newItem.name = "";
      this.newItem.url = "";
    },
    addPages() {
      const selectedPagesCopy = JSON.parse(JSON.stringify(this.selectedPages))
      selectedPagesCopy.forEach(page => {
        page.id = uuidv4()
      });
      this.menu = this.menu.concat(selectedPagesCopy);
      this.selectedPages = [];
    },
    removeFromMenu(id) {
      this.menu = remove(this.menu, id);
    },
    loadPages() {
      this.$WebPages
        .getCollection({ params: { pagination: false } }, 'id_name')
        .then((response) => {
          this.pages = response.data["hydra:member"];
          this.options = preparePageItems(this.pages);
        });
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const position = this.navigation.position.id ? this.navigation.position.id : this.navigation.position;
        iterate(this.menu, 0, false)
        const body = {
          position,
          menu: this.menu,
        }
        if (this.action === "update") {
          this.$Navigations
            .updateResource({ id: position, body })
            .then((response) => {
              if (response.status === 200) {
                this.$emit('clearAction')
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.navigationUpdated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                this.$emit('clearAction')
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }

        if (this.action === "add") {
          this.$Navigations
            .createResource({ body })
            .then((response) => {
              if (response.status === 201) {
                this.$emit('clearAction')
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.navigationCreated"),
                  "",
                  "success"
                );
                this.$router.push('/navigations');
              }
            })
            .catch((error) => {
              if (error.response) {
                this.$emit('clearAction')
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
