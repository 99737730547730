<template>
  <draggable class="dragArea" tag="ul" :list="items" :group="{ name: 'g1' }">
    <li v-for="(el, index) in items" :key="index">
      <div class="dragArea-item">
        <div class="dragg">
          <bootstrap-icon icon="arrows-move" size="1x" />
        </div>
        <b-form-input v-model="el.name" />
        <b-form-input v-model="el.url" />
        <div class="trash" @click="removeFromMenu(el.id)">
          <bootstrap-icon icon="trash" size="1x" />
        </div>
      </div>
      <nested-draggable @itemDeleted="removeFromMenu" :items="el.items" />
    </li>
  </draggable>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "nested-draggable",
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  components: {
    draggable: VueDraggableNext,
  },
  methods: {
    removeFromMenu(id) {
      this.$emit("itemDeleted", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.dragArea {
  list-style: none;
  margin: 0;
  padding: 0;
  ul {
    list-style: none;
    margin: 0;
    padding-left: 15px;
  }
  &-item {
    display: flex;
    padding-bottom: 5px;
    input {
      margin-right: 10px;
    }
    .dragg,
    .trash {
      height: 38px;
      min-width: 38px;
      border: 1px solid #ced4da;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.357rem;
    }
    .trash {
      cursor: pointer;
    }
    .dragg {
      margin-right: 10px;
    }
  }
}
</style>
